import React from "react";
import PropTypes from "prop-types";


import { useStorageImage } from "../hooks";


export function BackgroundImage({source, className="background-image", style={}, ...props}){
  const [image, state] = useStorageImage(source);
  return(<div {...props} data-testid="storage-image" className={className} data-state={state} style={{...style, backgroundImage:`url(${image})`}}></div>)
}
BackgroundImage.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
}
export function StorageImage({source, alt="", ...props}){
  const [image, state] = useStorageImage(source);
  return (<img data-testid="storage-image" src={image} alt={alt} data-state={state} {...props} />)
}
StorageImage.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
}