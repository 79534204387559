import React from "react";

import ErrorMessage from "./ErrorMessage";

import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Page404(){
  const {t} = useTranslation("ui");
  const location = useLocation();
  return(<div className="container">
    <ErrorMessage message={t("ui:notFound.message")} title={t("ui:notFound.title")} stack={`${location.pathname}`}/>
  </div>)
}