import React, {useContext} from "react";
import PropTypes from "prop-types";

import {UploadContext} from "../../context";

import Icon, {Upload} from "@holusion/uikit/dist/icons.js";

export function UploadButton({storageRef}){
  const {addUpload} = useContext(UploadContext);

  function handleFiles(e){
    const files = Array.from(e.target.files);
    addUpload(storageRef, {files});
  }
  return (<React.Fragment>
     <input data-test="upload-btn" style={{display:"none"}} type="file" multiple={true} onClick={e=> e.target.value = null} onChange={handleFiles} id="file-upload-button" data-testid="upload-input"/>
    <label data-test="button-upload" className="btn btn-outline-primary" htmlFor="file-upload-button" title="Envoyer un fichier"><Icon icon={Upload}/></label>
  </React.Fragment>)
}

UploadButton.propTypes = {
  storageRef: PropTypes.object.isRequired
}