import {useContext} from "react";


import { useCollection } from 'react-firebase-hooks/firestore';
import {FirebaseContext} from "../context";


import "firebase/firestore"; /*otherwise firestore is undefined for whatever reason even though it's been initialized in App.js */
import firebase from 'firebase/app';

/**
 * Fetch all contributed/owned applications for this UID.
 * Require caller to be an admin or to request for himself.
 * One can then check if appData.owner === uid to filter owned/contributed apps
 * @param {string} uid
 * @returns {Map} apps id => data map of applications
 */
export default function useUserApplications({uid, customClaims: claims}){
  const {app} = useContext(FirebaseContext);
  const [owned = {docs:[]}, loadingOwned, errorOwned] = useCollection(
    uid? app.firestore().collection("applications").where("owner", "==", uid): undefined
  )

  const filter = (claims && claims.contributes )?Object.keys(claims.contributes):[];
  const [contributions = {docs:[]}, loadingContributions, errorContributions] = useCollection(
    (0 < filter.length)? app.firestore().collection('applications').where(firebase.firestore.FieldPath.documentId(), "in", filter ): undefined
  )

  let apps = new Map([
    ...contributions.docs.map(d=> ([d.id, d.data()])),
    ...owned.docs.map(d=> ([d.id, d.data()])),
  ].sort());

  return [
    apps,
    loadingOwned || loadingContributions,
    errorOwned || errorContributions,
  ]

}