import React, {useState, useEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {FormSelector, ErrorMessage} from "../../../components";

import "./markdown.css";
import {useTransformMarkdown} from "./useTransformMarkdown";

import { Loader, useModal, StackModal, FakeEvent } from "@holusion/uikit";

import link from "./icons/link.svg";
import image from "./icons/image.svg";
import h1 from "./icons/h1.svg";
import bold from "./icons/bold.svg";
import italic from "./icons/italic.svg";


export function MarkdownPreview({value, medias, name}){
  const [content, loading, error] = useTransformMarkdown(value, medias);
  if(error){
    return <ErrorMessage message={error.message}/>
  }else if(loading){
    return <Loader/>
  }
  return <div name={name} dangerouslySetInnerHTML={{__html: content}}/>
}

export function MarkdownEdit({value:initialValue, placeholder="", name, onChange, pages=[], medias=[]}){
  const {t} = useTranslation(["ui"]);
  const ref = useRef();
  const textInput = useRef(null);
  const [value, setValue] = useState(initialValue || "");
  const [height, setHeight] = useState(0);
  const [selection, setSelection] = useState({start:value.length, end: value.length});
  const [addModal, removeModal] = useModal();

  useEffect(() =>{
    const node = ref.current;
    
    if(!node) return;
    if(typeof ResizeObserver !== "function") return;
    const obs = new ResizeObserver(([entry])=>{
      let  new_height = entry.contentRect.height + 20 /*add padding*/ + 20 /* add trailing line break*/;
      if(new_height !== height) setHeight(new_height);
    });
    obs.observe(node);
    return ()=> obs.unobserve(node);
  }, [setHeight, height]);

  useEffect(()=>{
    textInput.current.focus();
    textInput.current.setSelectionRange(selection.start, selection.end);
    
  }, [selection, textInput]);


  function handleChange(e){
    setValue(e.target.value);
    onChange(e);
  }

  function getSelection(){ return {start: textInput.current.selectionStart, end: textInput.current.selectionEnd}}

  function refineSelection(){
    const {start, end} = getSelection();
    let selectStart = start , selectEnd = end;
    let selectValue = value.substr(start,end-start);
    const regex = /[\s\n]/g;
    if (selectValue.startsWith(" ")){
      selectValue = selectValue.substr(1);
      selectStart++;
    }
    if (selectValue.endsWith(" ")){
      selectValue = selectValue.substr(0,selectValue.length-1);
      selectEnd--;
    }
    if(end===start && end!==0 && end!==value.length && !value.substr(0,selectStart).endsWith(" ") && !value.substr(selectEnd).startsWith(" ") && !value.substr(0,selectStart).endsWith('\n') && !value.substr(selectEnd).startsWith('\n')){
      (value.substr(selectEnd).search(regex) !== -1) ? 
      selectEnd += value.substr(selectEnd).search(regex) : selectEnd=value.length;
      (value.substr(0,selectStart).split('').reverse().join('').search(regex) !== -1) ? 
      selectStart -= value.substr(0,selectStart).split('').reverse().join('').search(regex) : selectStart=0;
    }
    return ({start:selectStart, end: selectEnd});
  }

  function handleClickHeader(){
    const {start, end} = getSelection();
    let previousLineBreak = value.substr(0,start).split('').reverse().join('').search('\n');
    (previousLineBreak !== -1) ? previousLineBreak = start-previousLineBreak : previousLineBreak = 0; 
    const nextLineBreak = (value.substr(end).search('\n') !== -1) ? end + value.substr(end).search('\n') : value.length;
    handleChange(new FakeEvent(name, value.substr(0,previousLineBreak) + "# " + value.substr(previousLineBreak)));
    setSelection({start:previousLineBreak+2, end: nextLineBreak + 2});
  }

  function handleClickBold(){
    const {start, end} = refineSelection();
    handleChange(new FakeEvent(name, value.substr(0,start) + "**" + value.substr(start,end-start) + "**" +value.substr(end)));
    setSelection({start:start+2, end: end+2});
  }

  function handleClickItalic(){
    const {start, end} = refineSelection();
    handleChange(new FakeEvent(name, value.substr(0,start) + "_" + value.substr(start,end-start) + "_" +value.substr(end)));
    setSelection({start:start+1, end: end+1});
  }

  function handleClickLink(){
    let id = addModal({
      type: StackModal,
      title: t("ui:add-link"),
      children: <div>
        <FormSelector data-testid="link-form" name="Link" items={pages} onChange={function onChange(e){
        let url = e.target.value;
        addMarkdownLink(url);
        removeModal(id)}}/>
        <button data-testid="link-custom" className="btn btn-outline-primary mt-2" onClick={() => {
        addMarkdownLink('url');
        removeModal(id);
        }}>{t("ui:add-custom-url")}</button>
      </div>
    }); 
  }

  function addMarkdownLink(url){
    const {start, end} = getSelection();
    handleChange(new FakeEvent(name, value.substr(0,start) + "[" +value.substr(start,end-start) + "](" + url + ")" +value.substr(end)));
    (url==='url') ? setSelection({start:end+3, end: end+6}): 
    ((start === end)? setSelection({start:start+1, end: start+1}) : 
    setSelection({start:start+1, end:end+1}))
  }

  function handleClickImage(){
    let id = addModal({
      type: StackModal,
      title: t("ui:add-image"),
      children: 
      <div>
        <FormSelector data-testid="image-form" name="Image" items={medias} onChange={function onChange(e){
        let url = e.target.value;
        addMarkdownImage(url);
        removeModal(id)}}/>
      </div>
    }); 
  }

  function addMarkdownImage(url){
    const {start, end} = getSelection();
    handleChange(new FakeEvent(name, value.substr(0,start) + "![" + ((start === end)? "alt text" : value.substr(start,end-start)) + "](" + url + ")" + value.substr(end)));
    (start === end) ? ((url==='url')? setSelection({start:start+12, end: start+15}) : setSelection({start:start+2, end: start+10})) : 
    setSelection({start:end + url.length + 5,end:end + url.length + 5})
  }

  return <div className="markdown-edit-area" >
    <div className="markdown-edit-clone" ref={ref} tabIndex={-1} >{value || "&nbsp;\n"}</div>
    <div className="bg-white border rounded">
      <div className="border-bottom" style={{color:'darkslategray'}}>
        <button data-testid="header-button" className="btn" onClick={handleClickHeader}><img alt="H1" src={h1}/></button>
        <button data-testid="bold-button" className="btn" onClick={handleClickBold}><strong><img alt="bold" src={bold}/></strong></button>
        <button data-testid="italic-button" className="btn" onClick={handleClickItalic}><i><img alt="italic" src={italic}/></i></button>
        <button data-testid="link-button" className="btn" onClick={handleClickLink}><img alt="addLink" src={link}/></button>
        <button data-testid="image-button" className="btn" onClick={handleClickImage}><img alt="addImage" src={image}/></button>
      </div>
      <textarea ref={textInput} data-test="markdown-edit" aria-multiline="true" type="text" 
        style={{height}} className="markdown-edit-text form-control border-0"
        name={name}
        onChange={handleChange} 
        placeholder={placeholder} value={value? value: ""}
      />
      
    </div>
  </div>
}


MarkdownEdit.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export function Markdown({edit, ...props}){
  if(edit){
    return <MarkdownEdit {...props}/>
  }else{
    return <MarkdownPreview {...props}/>
  }
}