import React, {useContext, useEffect, useState} from "react";

import {FirebaseContext} from "../context";
import firebase from 'firebase/app';
import 'firebase/auth';

import {useTranslation, Trans} from "react-i18next";
import conf from "../firebaseConfig";

import {useToast, useModal, TextInputModal, StackModal, Loader} from "@holusion/uikit";


import Icon, {Link} from "@holusion/uikit/dist/icons.js";

import logo from '../img/logo.svg';
import gLogo from "../img/google_G_Logo.svg";
import { useLocation } from "react-router-dom";


const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
  emailAuthProvider: new firebase.auth.EmailAuthProvider(),
};



function GoogleReauth({onConfirm, onDismiss}){
  const {t} = useTranslation(["ui", "help"]);
  const {app} = useContext(FirebaseContext);
  return (<button className="btn btn-outline-primary w-50" onClick={(e)=>{
    e.preventDefault();
    app.auth().signInWithPopup(providers["googleProvider"])
    .then(onConfirm, onDismiss)
  }}>
    <img className="img-fluid" width={24} src={gLogo} alt="google G logo"/>
    {t("ui:google-auth")}
  </button>)
}

function PasswordReauth({onConfirm, onDismiss}){
  const {t} = useTranslation(["ui", "help"]);
  const {app} = useContext(FirebaseContext);
  const {pathname} = useLocation();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [authMethods, setAuthMethods] = useState([]);
  const currentUser = app.auth().currentUser;
  function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(currentUser.email, password))
    .then(onConfirm, onDismiss)
  }
  function handleLinkAuth(){
    setLoading(true)
    app.auth().sendSignInLinkToEmail(currentUser.email, {url: `https://${conf.authDomain}${pathname}`, handleCodeInApp: true}).then(()=>{
      window.localStorage.setItem('emailForSignIn', currentUser.email);
    })
    .then(()=>{
      setError(new Error("Confirmation email sent. Please reload the page and try again"))
    })
    .catch((e)=>{
      onDismiss(e);
    })
    /*
    console.info("handling link auth");
    const credential = firebase.auth.EmailAuthProvider.credentialWithLink(
      currentUser.email, href);
    console.info("Street cred : ", credential);
    currentUser.reauthenticateWithCredential(credential)
    .then((...props)=>{
      console.info("Reauth success : ", props);
      onConfirm();
    })
    .catch((e)=>{
      console.warn("Reauth error : ",e);
      onDismiss();
    })
    //*/
  }

  useEffect(()=>{
    app.auth().fetchSignInMethodsForEmail(currentUser.email).then((methods)=>{
      setAuthMethods(methods);
    });

  }, [currentUser, app]);
  if(loading) return (<div><Loader/></div>)
  if(error) return (<div>{error.message}</div>)
  return (<div>
    {authMethods.length === 0 && <Loader/>}
    {authMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1 && <div className="form-group">
      <label htmlFor="old-password" >{t("ui:password")}</label>
      <div className="input-group">
        <input id="old-password" type="password" value={password} onChange={e=>setPassword(e.target.value)} className="form-control" placeholder="********" aria-label="password" name="password"/>
        <div className="input-group-append ">
          <button className="btn btn-outline-primary" onClick={handleSubmit}>
            {t("ui:send")}
          </button>
        </div>
      </div>
    </div>}

    { authMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1 && <div className="form-group">
       <button className="btn btn-outline-primary" onClick={handleLinkAuth}>
        <Icon icon={Link}/> {t("help:send-email-link")}
      </button>
    </div>}
  </div>)
}

export function ReAuthModal({onConfirm, onDismiss}){
  const {app} = useContext(FirebaseContext);
  const {t} = useTranslation(["ui", "help"]);
  const [addToast] = useToast();
  const [provider, setProvider] = useState();
  
  const currentUser = app.auth().currentUser;

  useEffect(()=>{
    let cancelled = false;
    currentUser.getIdTokenResult(false).then(token=>{
      if(!cancelled) setProvider(token.signInProvider);
    });

    return ()=>cancelled = true;
  }, [currentUser]);

  if(!provider){
    return <Loader page/>
  }


  const handleDismiss = (e)=>{
    addToast({severity: "warn", message: e.message});
    onDismiss(e);
  }

  let credProvider = (<div>Unsupported crendentials provider : {provider} (please contact your system admin)</div>);

  if(provider === "google.com"){
    credProvider= <GoogleReauth onConfirm={onConfirm} onDismiss={handleDismiss}/>
  }else if(provider === "password"){
    credProvider = <PasswordReauth onConfirm={onConfirm} onDismiss={handleDismiss}/>
  }


  return (<StackModal onDismiss={onDismiss}>
    <h3 className="text-center">{t("help:auth-refresh")}</h3>
    <div>
      {credProvider}
    </div>
  </StackModal>)
}




export default function Auth(props){
  const [addModal] = useModal();
  const [addToast] = useToast();
  const {href, pathname} = window.location;
  const {t, i18n} = useTranslation(["ui", "help", "errors"]);
  const {app} = useContext(FirebaseContext);
  const auth = app.auth();

  auth.languageCode = i18n.language;
  
  function createUser(user, password){
    if(! user){
      addToast({severity: "error", message: t("errors:invalid-email", {what: user})});
      return;
    } else if (!password){
      sendLink(user);
    }else{
      auth.createUserWithEmailAndPassword(user, password)
      .catch(err=>{
        return window.alert(err);
      })
    }
  }

  function signUp(e){
    e.preventDefault();
    const target = e.target.form;
    const username = target["email"].value;
    const password = target["password"].value;
    if(!username){
      addModal({
        type: TextInputModal, 
        title:t("ui:sign-up"),
        name: "email",
        text: {
          save: (<span>{t("ui:send")}</span>),
          close: t("ui:cancel"),
        },
        onConfirm: (value)=>{
          createUser(value);
        }
      })
      return;
    }
    return createUser(username, password);
  }

  function signIn(e){
    e.preventDefault();
    const target = e.target.form;
    const username = target["email"].value;
    const password = target["password"].value;
    auth.languageCode = i18n.language;
    auth.signInWithEmailAndPassword(username, password)
    .catch(err=>{
      if(err.code !== "auth/user-not-found"){
        return window.alert(err);
      }else if(window.confirm(t("help:create-user"))){
        signUp(e);
      }
    })
  }

  function signInWith(providerName){
    if(!providers[providerName]) throw new Error("No such provider : "+providerName);
    auth.languageCode = i18n.language;
    return auth.signInWithPopup(providers[providerName]);
  }

  function sendLink(email){
    auth.sendSignInLinkToEmail(email, {url: `https://${conf.authDomain}${pathname}` ,handleCodeInApp: true}).then(()=>{
      window.localStorage.setItem('emailForSignIn', email);
      addToast({severity: "info", message:t("help:email-sent")})
    }).catch((e)=>{
      addToast({severity: "error", message: e.message});
    })
  }

  /*handle email link signIns */
  useEffect(()=>{
    function doSignIn(email){
      auth.signInWithEmailLink(email, href)
      .then(function(result) {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        addToast({severity: "info", message:"signed in"});
      })
      .catch(function(error) {
        addToast({severity:"error", message: "email link signIn error : "+error.code});
      });
    }

    if (auth.isSignInWithEmailLink(href)) {
      if(window.localStorage.getItem('emailForSignIn')){
        doSignIn(window.localStorage.getItem('emailForSignIn'))
      }else {
        addModal({
          type: TextInputModal,
          title: t("help:provide-email"),
          text: {
            save: (<span>{t("ui:send")}</span>),
            close: t("ui:cancel"),
          },
          onConfirm: (value)=>{
            if(!value){
              addToast({severity: "warn", message: t("help:provide-email")});
              return;
            }
            doSignIn(value);
          }
        })
      }
    }
  }, [addToast, auth, addModal, t, href]);


  return (<main id="auth" data-test="auth" className="App d-flex justify-content-center flex-column flex-grow-1" >
    <div className="row no-gutters text-center">
      <div className="col-12 col-lg-6 p-2">
        <img src={logo} className=" w-50 shadow" alt="logo" />
      </div>
      <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <div style={{maxWidth:500, margin: "auto"}} >
          <div className="row" >
            <div className="col"><h2 className="text-primary"> {t("help:ask-auth")} </h2></div>
          </div>
          <div className="row">
            <div className="col" >
              <form className="d-block" data-test="signIn" onSubmit={signIn}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="email-label" style={{width:40}}>@</span>
                  </div>
                  <input type="email" className="form-control" placeholder="email" aria-label="email" aria-describedby="email-label" name="email"/>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="password-label" style={{width:40}}>#</span>
                  </div>
                  <input type="password" className="form-control" placeholder={t("ui:password")} aria-label="password" aria-describedby="password-label" name="password"/>
                </div>
                <div className="btn-group d-flex w-100 justify-content-end mb-3" role="group" aria-label="...">
                  <button className="btn btn-outline-primary w-50" type="button" onClick={(e)=>sendLink(e.target.form["email"].value)}> {t("ui:forgot-password")} </button>
                  <button className="btn btn-primary w-50" type="submit" data-test="submit" onClick={signIn}>{t("ui:sign-in")}</button>
                </div>
                <div className="btn-group d-flex w-100 mb-3" role="group" aria-label="alternative signin methods">
                  <button className="btn btn-outline-primary w-50" onClick={(e)=>{e.preventDefault();signInWith("googleProvider")}}>
                        <img className="img-fluid" width={24} src={gLogo} alt="google G logo"/>
                        {t("ui:google-auth")}
                  </button>
                  <button className="btn btn-outline-primary w-50" type="button" onClick={signUp}>
                        {t("ui:sign-up")}
                  </button>           
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col">
            <Trans i18nKey="help:cookie-consent" t={t} >
              This website will set a <a href={t("help:cookie-link")}>cookie</a> to manage authentication when you log in
            </Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>)
}
