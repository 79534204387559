import React from "react";
import PropTypes from "prop-types";


export default function Portrait(user){
  return (<div className="d-inline-flex px-2">
    <span className="d-none d-lg-block text-dark pr-2">{user.displayName || user.email}</span>
    {user.photoURL && <span className="bg-primary" style={{display:"block", width:24, height:24, borderRadius:"50%", background: `url("${user.photoURL}") center/cover`}}></span>}
  </div>)
}

Portrait.propTypes = {
  displayName: PropTypes.string,
  photoURL: PropTypes.string,
}