import React from "react";
import PropTypes from "prop-types";

export function formatBytes(bytes, si=true){
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
  }
  var units = si
      ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  var u = -1;
  do {
      bytes /= thresh;
      ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return Math.round(bytes*100)/100 + ' '+units[u];
}


export default function Bytes({children, si=true, radix=10, ...props}){
  const b = typeof children === "number"? children : parseInt(children, radix);
  return <span data-value={children} {...props}>{Number.isSafeInteger(b)?formatBytes(b, si):(<span title="not an number">NaN</span>)}</span>
}

Bytes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  si: PropTypes.bool,
  radix: PropTypes.number
}