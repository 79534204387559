import React from "react";



/**
 * 
 * @param {object} props 
 * @param {string} props.name property name
 * @param {string} [props.title=props.name] human-readable title
 * @param {string} props.field inner object referencing the property
 * @param {"text"|"color"|"number"|"url"} props.type <input type="?"> attribute. Also defines the value's type-cast
 * @param {undefined|"%"|"px"} props.unit unit to be displayed next to the value
 * @param {object} theme full theme object
 * @param {string} [props.placeholder] default value
 * @param {function} props.onChange
 * @param {()=>any} [props.onReset] reset callback
 * @returns {JSX.Element}
 */
export function ThemeInput({name, title=name, field, type="text", unit, value, placeholder, onChange, onReset}){
    if(typeof value ==="undefined"){
        value = "";
    }else if(typeof value ==="string" && type === "number" && value !== ""){
        value = parseFloat(value);
    }
    return(
    <div className="form-group form-row align-items-stretch">
        <label className="col-3 col-form-label" style={{minWidth:110}}>
            {title}
        </label>
        <div className="col">
            <div className="input-group h-100">
                <input
                    className="form-control" 
                    data-testid={"input-"+field +"-"+ name} 
                    data-field={field} 
                    type={type}
                    name={name} 
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}/>
                {unit? <div className="input-group-append"><span className="input-group-text">{unit}</span></div>:null}
            </div>
        </div>
        <div className="col-auto">
            {(typeof onReset === "function")? <button data-testid={"input-reset-button"} className="btn" data-field={field} name={name} onClick={onReset}>
                x
            </button>: null}
        </div>
    </div>        
    )
}