import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

export default function InnerNav({brand, links=[], ...props}){
  return (<div id="inner-navigation" className="collapse navbar-collapse" {...props}>
    {brand && <Link data-testid="inner-nav-brand" to={brand.to} className="navbar-brand ">
      {brand.content}
    </Link>}
    <ul className="nav navbar-nav m-auto" >
      {links.map(({to, content}, index)=>{
        let linkTo= typeof to === "string"? (l)=>`${to}${l.search}` : to;
        return (<li key={index} className="nav-item">
          <NavLink className={`nav-link`} exact to={linkTo}>{content}</NavLink>
        </li>)
      })}
    </ul>
  </div>
  )
}

const itemType = PropTypes.shape({
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
})
InnerNav.propTypes = {
  brand: itemType,
  links: PropTypes.arrayOf(itemType).isRequired
}

