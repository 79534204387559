import PropTypes from "prop-types";

export const BasePropTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  emptyValue: PropTypes.string,
}

export const BaseDefaultProps = {
  emptyValue: "Vide"
}

export const ItemShape = {
  value:PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const SelectorPropTypes = Object.assign({
  items: PropTypes.arrayOf( PropTypes.shape(ItemShape)).isRequired,
  minWidth: PropTypes.number,
}, BasePropTypes);


//Apply firestore doc ID constraints plus readability rules
export const valid_doc_id_re = "^(?!__)(?!\\.+)[^/\\t\\n\\r\\%]+$";