import React, {useState} from "react";
import { FlatList, Selector, Icon } from "@holusion/uikit";
import {Close, Plus} from "@holusion/uikit/dist/icons.js";

/**
 * input types. Should follow HTMLInputElement.type as much as possible
 */
const types = /** @type {const} */ (["text", "color" , "number"]);
const defaults = /** @type {const} */ ({
  text:"", 
  color:"#ffffff" ,
  number:0
});

/**
 * @typedef {object} AdditionalPropertyDescriptor
 * @param {typeof types[number]} type
 * @param {any} value
 */

function AdditionalProperty({name, type, value, onChange, onRemove}){

  function handleChange(e){
    const newValue = (type === "number")? parseFloat(e.target.value):e.target.value;
    onChange(newValue);
  }

  return (<li className="list-group-item">
  <div className="form-group form-row align-items-stretch">
    <label className="col-3 col-form-label text-right" style={{minWidth:110}}>{name}</label>
    <div className="col">
      <input className="form-control h-100" type={type} name={name} value={value||""} onChange={handleChange}></input>
    </div>
    <div className="col-auto">
      {typeof onRemove ==="function"? <span role="button" onClick={()=>onRemove(name)} data-testid="flatlist-add" className="btn btn-sm btn-warning"><Icon icon={Close}/></span>: null}
    </div>
  </div>
</li>)
}

/**
 * 
 * @param {object} props
 * @param {(AdditionalPropertyDescriptor & {name:string}) => any} props.onAdd
 * @param {}
 * @returns 
 */
function CreateAdditionalProperty({onAdd}){
  const [newName, setNewName] = useState("");
  const [newType, setNewType] = useState(types[0]);

  return (<li className="list-group-item">
    <div className="form-group form-row align-items-center">
      <div className="col">
        <input className="form-control" name="name" placeholder="prop-name" value={newName} type="text" onChange={e=>setNewName(e.target.value)}/>
      </div>
      <div className="col">
        <Selector className="form-control" title="prop-type" name="type" value={newType} items={types} onChange={(e)=>setNewType(e.target.value)}/>
      </div>
      <div className="col-auto">
      <span role="button" onClick={()=>{onAdd({name:newName, type: newType, value: defaults[newType]}); setNewName(""); setNewType(types[0])}} data-testid="flatlist-add" className="btn btn-sm btn-primary"><Icon icon={Plus}/></span>
      </div>
    </div>
</li>)
}

/**
 * 
 * @param {object} props
 * @param {(AdditionalPropertyDescriptor & {name:string}) => any} props.onChange
 * @param {(name:string)=>any} props.onRemove
 * @param {{[string]: AdditionalPropertyDescriptor}} props.items
 * @returns 
 */
export function AdditionalProperties({onChange, onRemove, items}){
  return <FlatList 
    name="additionalProperties"
    items={Object.keys(items).map(name =>({
      name, 
      ...items[name],
      onChange: (value)=>onChange({name, ...items[name], value}),
    }))} 
    item={AdditionalProperty} newItem={CreateAdditionalProperty} 
    onAdd={onChange}
    onRemove={onRemove}
  /> 
}