import React, {useContext, useEffect, useRef} from "react";

import PropTypes from "prop-types";

import {FirebaseContext} from "../context";

import { useDownloadURL } from 'react-firebase-hooks/storage';

import {Loader} from "@holusion/uikit";

export default function StorageVideo({source, controls=true, alt, ...props}){
  const {app} = useContext(FirebaseContext);
  const [href, loading] = useDownloadURL(
    app.storage().refFromURL(source)
  );
  if(loading) return <Loader id="image-loader" inline={true}/>
  else if(!href) return (<img src="/failed_image.png" title={`can't get media ${source} from cloud storage`} alt={alt?alt:""} {...props} />)
  else if(/(?:mp4|webm|ogg|mkv|mov)$/i.test(href)){
    return (<video className="embed-responsive-item" controls={controls} src={href} alt={alt?alt:""} {...props} />)
  }else{
    return <ModelView href={href}/>
  }
}

StorageVideo.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
}


function ModelView({href}){
  const ref = useRef();

  useEffect(()=>{
    const iframeDocument = ref.current?.contentWindow?.document;
    if(!iframeDocument) return;
    console.log("write iframe");
    iframeDocument.open();
    iframeDocument.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Model Viewer</title>
        <style>
          html, body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
          model-viewer {
            width: 100%;
            height: 100%;
          }
        </style>
        <script type="module" src="https://ajax.googleapis.com/ajax/libs/model-viewer/3.3.0/model-viewer.min.js"></script>
      </head>
      <body>
      <model-viewer src="${href}" shadow-intensity="1" camera-controls ></model-viewer>
      </div>
      </body>
    </html>
  `);
    iframeDocument.close();
  }, [ref.current]);
  return (
    <iframe  className="embed-responsive-item" ref={ref}>
    </iframe>
  )
}