import React from "react";
import {useTranslation} from "react-i18next";

export function EmptyPageMessage(){
    const {t} = useTranslation("tutorial");
    return(
    <div className="text-primary p-3">
        <h3 className="mb-4">{t("page-welcome")}</h3>
        <p>{t("page-preview")}</p>
        <p>{t("page-edition")}</p>
    </div>)
}