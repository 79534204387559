import React, {useState} from "react";
import PropTypes from "prop-types";
import {BaseDefaultProps, SelectorPropTypes} from "./shapes"


export {FakeEvent} from "@holusion/uikit";

export function MultiSelector(props){
  const [value, setValue] = useState(Array.isArray(props.value)? props.value : []);
  function onChange(e){
    const state = e.target.checked;
    const valueName = e.target.value;
    let newValue;
    if(state){
      newValue = [...value, valueName];
    }else{
      newValue = value.filter(v => v !== valueName);
    }
    if(typeof props.onChange !== "function") return console.warn("MultiSelector does not have an onChange callback :", e.target);
    setValue(newValue);
    props.onChange({preventDefault:function(){}, target:{name:props.name, value: newValue}});
  }
  return (<div id={props.name} className="input-group">
    <div className="input-group-prepend">
      <label htmlFor={props.name} className="input-group-text" style={{minWidth:props.minWidth? props.minWidth : 100, textAlign: "right"}}>{props.title? props.title : props.name}</label>
    </div>
    <fieldset disabled={props.disabled} className="d-flex align-items-center pl-4 justify-content-around flex-grow-1" style={{border:"1px solid #ced4da", borderTopRightRadius:".25rem", borderBottomRightRadius:".25rem"}}>
      {props.items.map(item => (<div key={item.value} className="form-check">
        <input onChange={onChange} className="form-check-input" type="checkbox" value={item.value} id={`check${item.value}`} checked={value.indexOf(item.value) !== -1}/>
        <label className="form-check-label" htmlFor={`check${item.value}`}>{item.name}</label>
      </div>))}
    </fieldset>

  </div>)
}
MultiSelector.propTypes = Object.assign({}, SelectorPropTypes, {
  value:PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
});
MultiSelector.defaultProps = BaseDefaultProps;

export {withState} from "./withState";
export {JSONInput} from "./JSONInput";

export {ColorPicker} from "./ColorPicker";

export * from "./ListConstructor";
export * from "./Links";
export * from "./FormInputs";
export * from "./Markdown";
export * from "./ThemeInputs";
export * from "./AdditionalProperties";