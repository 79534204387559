import React from "react";
import {withState, Selector} from "@holusion/uikit";
import {useTranslation} from "react-i18next";


import ImageLinkEditForm from "./ImageLinkEditForm";
import RectLinkEditForm from "./RectLinkEditForm";
import ShapeLinkEditForm from "./ShapeLinkEditForm";

import "./LinkEditForm.css";


function _TextInput(props){
  return <input type="text" {...props}/>
}

const TextInput = withState(_TextInput);


function TargetSelector({options, value, onChange, ...rest}){
  const withOptions = Array.isArray(options) && 1 <= options.length && (!value || options.find(({value:v})=> v === value));

  if(withOptions){
    return <Selector data-testid="link-target-selector" className="custom-select form-control" name="name" value={value} items={options} onChange={onChange} emptyValue="" {...rest} />
  }else{
    return (<TextInput data-testid="link-target-input" className={`form-control flex-grow-0 w-25`} name="name" value={value} onChange={onChange} {...rest}/>)
  }
}




export default function LinkEditForm({images, linkStyle, handleChange, index, options, linksCount, onRemove}){
  const {t} = useTranslation(["ui"]);


  let shapeForm;

  const onChange = (e)=>{
    if(e.target?.name){
      console.log("change %s to %s", e.target.name, e.target.value); 
      handleChange(index, {...linkStyle, [e.target.name]: e.target.value});
    }else{
      handleChange(index, {...linkStyle, ...e});
    }
  }
  if(linkStyle.shape === "rect"){
    shapeForm = (<RectLinkEditForm linkStyle={linkStyle} onChange={onChange}/>)
  }else if(linkStyle.shape === "image"){
    shapeForm = (<ImageLinkEditForm linkStyle={linkStyle} images={images} onChange={onChange}/>)
  }else{
    shapeForm = (<ShapeLinkEditForm linkStyle={linkStyle} onChange={onChange}/>)
  }
  return(
    <div>
      <TargetSelector options={options} value={linkStyle.name || ""} onChange={onChange} aria-describedby={`list-item-${index}`} title="target"/>
      {shapeForm}
      <div className="text-right">
        <button data-testid="duplicate" aria-label="duplicate" onClick={()=>handleChange(linksCount+1, linkStyle)} className="btn btn-primary mx-1 mt-1"><span>{t("ui:duplicate")}</span></button>
        <button data-testid="remove" aria-label="remove" onClick={()=>onRemove(index)} className="btn btn-danger mx-1 mt-1"><span>{t("ui:delete")}</span></button>
      </div>
    </div>
  )
}