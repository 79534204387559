import React, { useState } from "react";

import {withState} from "./withState";
import { FakeEvent } from "@holusion/uikit";

function InternalInput({value, ...props}){
  let rows = value? value.split("\n").length +1 : 4;
  return <textarea type="text" rows={rows} value={value} {...props}/>
}
const ConnectedInternalInput = withState(InternalInput);

/**
 * @deprecated Do not use (or refactor and test before use)
 */
export function JSONInput({onChange, value, className, ...props}){
  const [isValid, setValid] = useState(true);
  console.log("JSON input value : ", value);
  function handleChange(e){
    try{
      let v = e.target.value?JSON.parse(e.target.value): "";
      setValid(true);
      onChange(new FakeEvent(e.target.name, v));
    }catch(e){
      setValid(false);
    }
  }
  let classes = className? className+" ": "form-control ";
  classes = classes+ " is-"+ (isValid?"valid":"invalid");
  return <ConnectedInternalInput onChange={handleChange} className={classes} value={JSON.stringify(value, null, 2)} {...props}/>
}