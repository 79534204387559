import React, {useState} from "react";
import PropTypes from "prop-types";

import Icon, {Close, Check} from "@holusion/uikit/dist/icons.js";

import { ItemShape, SelectorPropTypes } from "./shapes"

/**
 * @typedef Item
 * @property {string} value
 * @property {string} name
 * @property {string} [id] - item's unique ID. If not provided, name will be used
 */

/**
 * @callback ChangeCallback
 * @param {string} name - the target item name
 */

/**
 * @callback RenderItemCb
 * @param {object} param0
 * @param {Item} param0.item
 * @param {Function} param0.onRemove 
 */

/**
 * @type RenderItemCb
 */
const defaultRender = ({item, onRemove})=>(<ListItem key={item.id || item.name} onRemove={onRemove} {...item}></ListItem>);

/**
 * 
 * @param {object} props
 * @param {ChangeCallback} props.onAdd
 * @
 */
export function ListAdd({onAdd, addPlaceholder}){
  const [newName, setNewName] = useState("");
  return <li data-testid="list-add" className="list-group-item d-flex justify-content-between align-items-center">
  <input className="form-control pr-1" placeholder={addPlaceholder} name="new-name" value={newName} type="text" onChange={e=>setNewName(e.target.value)}/>
  <button onClick={()=>{onAdd(newName); setNewName("")}} className="btn badge badge-success badge-pill"><Icon icon={Check}/></button>
</li>
}

/**
 * 
 * @param {object} props
 * @param {Array<Item>} props.items
 * @param {ChangeCallback} [props.onRemove] - onRemove callback that gets called if an item is removed - remove icon is hidden if callback is not provided
 * @param {ChangeCallback} [props.onAdd] - onAdd callback for default add field - default add line is hidden if it is not provided
 * @param {string} [props.addPlaceholder] - Input placeholder for the "add item" line
 * @param {string} [props.name] - Root element's ID - will also be used as a default title
 * @param {RenderItemCb} [props.renderItem] - item render custom function.
 * @param {Function} [props.footer] - footer components: will be added at the bottom of the list
 */
export function ListConstructor({
  items,
  onRemove,
  onAdd,
  addPlaceholder,
  name="",
  renderItem=defaultRender,
  footer=null,
  header=null
}){

  return <ul data-testid="list-construct" id={name} className="list-group list-group-flush">
    {header}
    {items.map((i, index)=>renderItem({item: i, index, onRemove}))}
    {typeof onAdd === "function" &&  <ListAdd onAdd={onAdd} addPlaceholder={addPlaceholder}/>}
    {footer}
  </ul>
}

ListConstructor.propTypes =  {
  ...SelectorPropTypes,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  footer: PropTypes.node,
};

export function ListItem({onRemove, name, value, title, children}){
  return (<li data-testid="list-item" className="list-group-item d-flex justify-content-between align-items-center">
      {title || name}
      {children}
      {typeof onRemove === "function" && <button data-testid="remove" aria-label="remove" onClick={()=>onRemove(value)} className="btn badge badge-danger hover-edit badge-pill"><Icon icon={Close}/></button>}
  </li>)
}

ListItem.propTypes = ItemShape;
