import React from "react";

import Icon, {Back} from "@holusion/uikit/dist/icons.js";

import { useParams, useRouteMatch } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import {InnerNav} from "../../components";

export default function ItemNav(){
  const { t } = useTranslation(["ui"]);
  const {app} = useParams();
  const {url} = useRouteMatch();
  return (<InnerNav data-testid="inner-nav-item"
    brand={{to: ({search}) => `/applications/${app}/pages${search}`, content: (<span className="d-flex align-items-center">
      <Icon icon={Back}/><span className="pl-2">{app}</span>
    </span>)}}
    links={[
      {to: `${url}`, content: t("nav.show")},
      {to: `${url}/edit`, content: t("nav.edit")},
      {to: `${url}/links`, content: t("nav.links")},
    ]}
  />)
}
