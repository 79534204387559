import React from "react";
import {useTranslation} from "react-i18next";

import StyleInput from "../../StyleInput";

export default function ShapeLinkEditForm({onChange, linkStyle}){
  const {t} = useTranslation(["ui"]);
  return <div>
      <div className="bg-light p-2 mt-1">
        <span className="font-weight-bold">Aspect</span>
        <div className="mt-1 d-flex">
          <StyleInput type="color"  name="fill" color={linkStyle.fill} onChange={onChange}/>
          <span className="px-2">{t("ui:fill")}</span>
        </div>
        <div className="mt-1 d-flex">
          <StyleInput type="color" title="stroke" name="stroke" color={linkStyle.stroke} onChange={onChange}/>
          <span className="px-2">{t("ui:outline")}</span>
          <StyleInput type="number" title="strokeWidth" name="strokeWidth" value={linkStyle.strokeWidth} onChange={onChange}/>
        </div>         
      </div>
      <div className="bg-light p-2 mt-1">
        <span className="font-weight-bold">{t("ui:path")}</span>
        <StyleInput className={`form-control flex-grow-1`} title="path" name="d" value={linkStyle.d} onChange={onChange}/>
        <small>
          <a target="_blank" rel="noopener noreferrer" href="https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths">Commandes pour créer un chemin SVG &lt;path&gt;</a>
        </small>        
      </div>

    </div>
}