import {useEffect, useState, useContext} from "react";
import {FirebaseContext} from "../context";


export default function useDevices(application){
  const {app} = useContext(FirebaseContext);
  const [devices, setDevices] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  useEffect(()=>{
    let cancelled = false;
    const listDevices = app.functions("europe-west1").httpsCallable("https_listDevicesCall");
    listDevices({application}).then(({data})=>{
      if(cancelled) return;
      setDevices(data);
    }).catch((e)=>{
      if(cancelled) return;
      setError(e);
    }).then(()=> setLoading(false))

    return ()=> {cancelled = true}
  }, [app, application, refreshKey]);

  return  [devices, loading, error, ()=>setRefreshKey(refreshKey+1)];
}
