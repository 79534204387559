import React from "react";
import {useTranslation} from "react-i18next";

export default function DisplayUid({uid}) {
  const {t} = useTranslation("ui");
  return (
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label htmlFor="uid" className="input-group-text" >{t("help:my-uid")}</label>
        </div>
        <input id="uid"
          data-testid="user-id" 
          readOnly type="text" placeholder="uid"
          className="form-control"
          aria-label="user-uid" aria-describedby="user-uid"
          value= {uid}
        />
      </div>
  )
}