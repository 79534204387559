import React from "react";
import {useTranslation} from "react-i18next";

import {FontSizeIcon, TextColorIcon, BorderRadiusIcon, TextLeftIcon, TextRightIcon, TextCenterIcon, VerticalTopIcon, VerticalCenterIcon, VerticalBottomIcon} from "../icons"
import StyleInput from "../../StyleInput";

export default function ShapeLinkEditForm({onChange, linkStyle}){
  const {t} = useTranslation(["ui"]);
  return (<div className="rect-link-edit-form">

    <div className="bg-light p-2 mt-1">
      <span className="font-weight-bold">Transformation</span>
      <div className="d-flex">
        <StyleInput label={"X"} type="number" title="x" name="x" value={linkStyle.x} onChange={onChange}/>
        <StyleInput label={"Y"} type="number" title="y" name="y" value={linkStyle.y} onChange={onChange}/>
      </div>
      <div className="d-flex mt-1">
        <StyleInput label={"W"} type="number" title="w" name="width" value={linkStyle.width} onChange={onChange}/>
        <StyleInput label={"H"} type="number" title="h" name="height" value={linkStyle.height} onChange={onChange}/>
      </div>
    </div>

    <div className="bg-light p-2 mt-1">
      <span className="font-weight-bold">Aspect</span>
      <div className="mt-1 d-flex">
        <StyleInput type="color"  name="fill" color={linkStyle.fill} onChange={onChange}/>
        <span className="px-2">{t("ui:fill")}</span>
      </div>
      <div className="mt-1 d-flex">
        <StyleInput type="color" title="stroke" name="stroke" color={linkStyle.stroke} onChange={onChange}/>
        <span className="px-2">{t("ui:outline")}</span>
        <StyleInput type="number" title="strokeWidth" name="strokeWidth" value={linkStyle.strokeWidth} onChange={onChange}/>
      </div>
      <div className="mt-1 d-flex">
        <span className="d-flex align-items-center"><BorderRadiusIcon width="16px" height="16px"/></span>
        <span className="px-2">{t("ui:radius")}</span>
        <StyleInput type="number" title="borderRadius" name="borderRadius" value={linkStyle.borderRadius} onChange={onChange}/>
      </div>
    </div>
    <div className="bg-light p-2 mt-1">
      <span className="font-weight-bold">Text</span>
      <div className="mt-1 d-flex justify-content-center">
        <button onClick={()=>onChange({textAlign: "left"})} className={`btn btn-icon btn-outline-${linkStyle.textAlign === "left"?"secondary":"dark"}`}><TextLeftIcon/></button>
        <button onClick={()=>onChange({textAlign: "center"})} className={`btn btn-icon btn-outline-${(!linkStyle.textAlign || linkStyle.textAlign === "center") ?"secondary":"dark"}`}><TextCenterIcon/></button>
        <button onClick={()=>onChange({textAlign: "right"})} className={`btn btn-icon btn-outline-${linkStyle.textAlign === "right"?"secondary":"dark"}`}><TextRightIcon/></button>
      </div>
      <div className="mt-1 d-flex justify-content-center">
        <button onClick={()=>onChange({verticalAlign: "top"})} className={`btn btn-icon btn-outline-${linkStyle.verticalAlign === "top"?"secondary":"dark"}`}><VerticalTopIcon/></button>
        <button onClick={()=>onChange({verticalAlign: "center"})} className={`btn btn-icon btn-outline-${(!linkStyle.verticalAlign || linkStyle.verticalAlign === "center") ?"secondary":"dark"}`}><VerticalCenterIcon/></button>
        <button onClick={()=>onChange({verticalAlign: "bottom"})} className={`btn btn-icon btn-outline-${linkStyle.verticalAlign === "bottom"?"secondary":"dark"}`}><VerticalBottomIcon/></button>
      </div>
    </div>

    <div className="bg-light p-2 mt-1">
      <span className="text-capitalize font-weight-bold">{t("ui:text")}</span>
      <div className="mt-1">
        <StyleInput className="form-control" name="text" title="text" value={linkStyle.text} onChange={onChange}/>
      </div>
      <div className="mt-1 d-flex">
        <StyleInput label={<TextColorIcon/>} type="color" title="textColor" name="textColor" color={linkStyle.textColor} onChange={onChange}/>
        <StyleInput label={<FontSizeIcon/>} type="number" title="fontSize" name="fontSize" value={linkStyle.fontSize} onChange={onChange}/>
      </div>
    </div>

  </div>)
}