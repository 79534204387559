import { useMemo } from "react"

export default function useCSSTheme(appTheme = {}){

    const style = useMemo(()=>({
        h1: {
            color: appTheme.color?.primary || "#00517d",
            fontSize: (appTheme.fontSize?.h1 || 32) + "px",
            fontFamily: appTheme.fontFamily?.h1
        },
        h2: {
            color: appTheme.color?.secondary || "#ff9966",
            fontSize: (appTheme.fontSize?.h2  || 24) + "px",
            fontFamily: appTheme.fontFamily?.h2
        },
        text: {
            color: appTheme.color?.default || "#000000",
            fontSize: (appTheme.fontSize?.default || 16) + "px",
            fontFamily: appTheme.fontFamily?.default
        },
        description: {
            background: appTheme.baseDescription?.backgroundColor || "#f1f1f1",
            height:  appTheme.baseDescription?.height || "100%",
            width: appTheme.baseDescription?.width || "33%",
            right: appTheme.baseDescription?.right || "0%",
            top: appTheme.baseDescription?.top || "0%",
            position: appTheme.baseDescription?.backgroundColor === "transparent" ? "absolute" : "relative",
            overflowY:"auto",
            padding: 20
        },
        btn: {
            fontFamily: appTheme.fontFamily?.btn
        },
        screen: {
            width: (appTheme.screen?.width ?? 1024) +"px",
            height: (appTheme.screen?.height ?? 768) +"px",
            title: (appTheme.screen?.title ?? 42) +"px",
        }
    }), [appTheme]);

    return style
}