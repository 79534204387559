import React, {useEffect, useState, useMemo} from 'react';
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import loadable from '@loadable/component';

import '@holusion/theme/dist/bootstrap.min.js'; /* import bootstrap's javascript */

import '@holusion/theme';
import '@holusion/uikit/dist/main.css'
import './App.css';

import {ErrorBoundary, ToastProvider, ModalProvider, Loader} from "@holusion/uikit";

import Auth from "./features/Auth";

import {ErrorMessage} from "./components";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import firebaseConfig from './firebaseConfig';
import {FirebaseContext} from "./context";

import { useAuthState } from 'react-firebase-hooks/auth';

import './i18n';
import Navbar from './features/Navbar';

import initErrorHandler from './errorReports';

const Routes = loadable(() => import(/* webpackChunkName: "routes" */ /* webpackPreload: true */ './routes'));




function ReporterErrorBoundary({children, errorHandler}){
  const {key} = useLocation();
  function reportError(error, details){
    const str = error.toString() + details;
    errorHandler.report(str);
  }
  
  return (<ErrorBoundary timeout={3000} reporter={reportError} weakKey={key}>
    {children}
  </ErrorBoundary>)
}

/*
 * Main App Component
 */
export default function App() {
  const firebaseApp = useMemo(()=>{
    let app = firebase.initializeApp(firebaseConfig);
    if (document.location.hostname === "127.0.0.1") {
      app.auth().useEmulator("http://localhost:9099");
      app.firestore().useEmulator("localhost", 8080);
      app.storage().useEmulator("localhost", 9199);
      app.functions().useEmulator("localhost", 5001);
    }
    return app;
  }, []);
  const errorHandler = useMemo(()=>initErrorHandler(), []);
  const [user, initialising, error] = useAuthState(firebaseApp.auth());
  const [claims, setClaims] = useState(null);
  const [expectedClaims, setExpectedClaims] = useState();


  
  useEffect(()=>{
    if(initialising || !user) return;
    const userDataRef = firebaseApp.firestore().doc(`users/${user.uid}`);
    const unsubscribe = userDataRef.onSnapshot(function onUserSnapshot(doc){
      console.info("user document updated to :", JSON.stringify(doc.data()));
      setExpectedClaims(doc.data());
    }, function onError(e){
      console.error("User document snapshot error : ", e);
    });
    return unsubscribe;
  }, [user, initialising, firebaseApp]);

  useEffect(()=>{
    if(initialising || !user || !expectedClaims) return;
    errorHandler.setUser(user.email);
    let cancelled = false;
    async function refresh(){
      let delay = 100;
      const waitFor = (d) => new Promise(r => setTimeout(r, d));
      while(!cancelled){
        if(15000 < delay) return console.warn("Max delay reached for token update (failed)");
        const token = await user.getIdTokenResult(true);
        if(token && token.claims.role === expectedClaims.role 
          && Object.keys(token.claims.contributes || []).length ===   Object.keys(expectedClaims.contributes|| []).length){
          setClaims(token.claims);
          break;
        }else{
          console.info("Token is stale. Refreshing...");
          await waitFor(delay);
          delay = delay*1.5;
        }
      }
    }
    refresh();
    return ()=> { cancelled = true }
  }, [user, initialising, expectedClaims, errorHandler])



  /* 
   * Actual component rendering
   */
  let children;
  if(initialising || (user && !claims)){
    children = (<main className="pt-2"><Loader id="app-main-loader" page={true}/></main>)
  }else if(error){
    children = (<ErrorMessage {...error}/>)
  }else if(!user){
    children = (
      <Auth />
    );
  }else{
    children = (<main data-test="main" className="pt-2">
      <Routes user={user} fallback={(<Loader id="app-main-loader2" page={true}/>)} />
    </main>)
  }
  return (<FirebaseContext.Provider value={{app:firebaseApp, claims}}>
    <Router>  
        <Navbar user={user}/>
        <ToastProvider timeout={3000}>
          <ReporterErrorBoundary errorHandler={errorHandler}>
            <ModalProvider>
              {children}
            </ModalProvider>
          </ReporterErrorBoundary>
        </ToastProvider>
    </Router>
  </FirebaseContext.Provider>)
}

