import {useEffect, useState, useContext} from "react";
import {FirebaseContext} from "../context";


export default function useListUsers(pageToken, maxResults=1000){
  const {app} = useContext(FirebaseContext);
  ;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(()=>{
    let cancelled = false;
    const listUsers = app.functions("europe-west1").httpsCallable("https_listUsersCall");
    listUsers({maxResults}).then(({data})=>{
      if(cancelled) return;
      setData(data);
    }, (e)=>{
      if(cancelled) return;
      setError(e);
    }).then(()=> {
      if(cancelled) return;
      setLoading(false);
    });
    return ()=> {cancelled = true}
  }, [app, pageToken, maxResults]);
  return  [data.users, loading, error, data.pageToken];
}
