import {useEffect, useState, useContext} from "react";
import {FirebaseContext} from "../context";

/* function restricted to admin use. Regular users will get an error */
export default function useBackups(){
  const {app} = useContext(FirebaseContext);
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(()=>{
    let cancelled = false;
    const listBackups = app.functions("europe-west1").httpsCallable("https_backupOperationsCall");
    listBackups({task:"list"}).then(({data})=>{
      if(cancelled) return;
      setBackups(data.backups);
    }).catch((e)=>{
      if(cancelled) return;
      setError(e);
    }).then(()=> setLoading(false))
    return ()=> {cancelled = true}
  }, [app]);

  return  [backups, loading, error];
}
