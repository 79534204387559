import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useCSSTheme, useStorageFile,} from "../hooks";
import { useToast } from "@holusion/uikit";
import "./ThemeProvider.css"

export function Font({name, uri}){
    let [url, loading, error] = useStorageFile(uri);
    let [addToast, removeToast] = useToast();
    useEffect(()=>{
        if(error){
            return removeToast.bind(null,addToast(`Fail to load ${name} : ${error.message}`));
        }else if(loading) return;
        let toastId = 0;
        let fontFace = new FontFace(name,`url(${url})`)
        fontFace.load().catch(e=> {
            if(toastId === 0) addToast("Fail to load "+name+" : "+ e.message)
        });
        document.fonts.add(fontFace);
        return ()=>{
            if(0 < toastId) {
                removeToast(toastId);
                toastId = -1; //Prevent addToast() after remove
            }
            document.fonts.delete(fontFace);
        }
    }, [name, url, loading, error, addToast, removeToast]);
    return null;
}

Font.propTypes = {
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
}

export default function ThemeProvider({appTheme, medias, children}){
    const fonts = medias? medias.filter(m => /\.(ttf|otf)$/.test(m.name) && m.fontData?.fontName)  : [];
    const fontfaces = fonts.map((font, index) => <Font key={index} name={font.fontData.fontName} uri={font.uri}/>);
    const style = useCSSTheme(appTheme);

    useEffect(()=>{

        document.documentElement.style.setProperty('--theme-color-primary', style.h1.color)
        document.documentElement.style.setProperty('--theme-color-secondary', style.h2.color)
        document.documentElement.style.setProperty('--theme-color-default', style.text.color)

        document.documentElement.style.setProperty('--theme-fontsize-h1', style.h1.fontSize)
        document.documentElement.style.setProperty('--theme-fontsize-h2', style.h2.fontSize)
        document.documentElement.style.setProperty('--theme-fontsize-default', style.text.fontSize)

        document.documentElement.style.setProperty('--theme-fontfamily-h1', style.h1.fontFamily)
        document.documentElement.style.setProperty('--theme-fontfamily-h2', style.h2.fontFamily)
        document.documentElement.style.setProperty('--theme-fontfamily-default', style.text.fontFamily)
        document.documentElement.style.setProperty('--theme-fontfamily-btn', style.btn.fontFamily)

        document.documentElement.style.setProperty('--theme-screen-width', style.screen.width)
        document.documentElement.style.setProperty('--theme-screen-height', style.screen.height)
    }, [style]);

    return(
        <div className="custom-theme">
            {fontfaces}
            {children}
        </div>    
    )
}