import React from "react";
import {useTranslation} from "react-i18next";

import StyleInput from "../../StyleInput";

export default function ImageLinkEditForm({onChange, images, linkStyle}){
  const {t} = useTranslation(["ui"]);

  let imageRatio;
  const currentImage = images.find( image => image.uri === linkStyle.image);
  imageRatio = currentImage ? currentImage.width / currentImage.height : 0.75 ;

  return <div className="image-link-edit-form">
      <div className="bg-light p-2 mt-1">
        <span className="font-weight-bold">Image</span>
        <div className="input-group">
          <StyleInput type="selector" emptyValue={t("empty")} name={"image"} value={linkStyle.image} items={images.map(({uri, name})=>({value: uri, name}))} onChange={onChange}/>
        </div>
      </div>

      <div className="bg-light p-2 mt-1">
        <span className="font-weight-bold">Transformation</span>
        <div className="d-flex">
          <StyleInput label={"X"} type="number" title="position x" name="x" value={linkStyle.x} onChange={onChange}/>
          <StyleInput label={"Y"} type="number" title="position y" name="y" value={linkStyle.y} onChange={onChange}/>       
        </div>
        <div className="d-flex mt-1">
          <StyleInput label={"Size"} type="number" title="width" name="width" value={linkStyle.width} onChange={(e)=>{
            onChange({height:  parseInt(e.target.value*imageRatio), width: e.target.value});
          }}/>
        </div>        
      </div>
    </div>
}
