import React, {useContext} from "react";

import { useDrop } from 'react-dnd'
import {NativeTypes} from 'react-dnd-html5-backend';

import {UploadContext} from "../../context";

import "./dropzone.css";

export function DropZone({storageRef, children, className, ...otherProps}){
  const {addUpload} = useContext(UploadContext);
  const [{isDragging}, drop] = useDrop({
    accept: NativeTypes.FILE,
    drop(item, monitor) {
      if (addUpload && storageRef) {
        addUpload(storageRef, item, monitor);
      }else{
        console.warn("drop handler not (or badly) configured to handle : ", item, monitor);
      }
    },
    collect: monitor => ({
        isDragging: monitor.isOver({shallow: false}) && monitor.canDrop(),
    })
  })
  return (<div ref={drop} className={`drop-zone${isDragging?" drag":""}${className?" "+className:""}`} {...otherProps}>
    {children}
  </div>)
}
