//Simple wrapper to make an augmented claims object
import {useContext} from "react";
import {FirebaseContext} from "../context";

class Claims{
  constuctor(){
    this.contributes = {};
  }
  isSuperuser(){
    return (this.role && this.role === "SUPERUSER")? true : false;
  }
  isAdmin(){
    return (this.role && (this.role === "ADMIN" || this.role === "SUPERUSER")) ? true : false;
  }
  isOwner(appData){
    if(typeof appData !== "object"){
      console.warn("claims.isOwner() requires the application's data object or at least it's owner field. %s given.", typeof appData);
      return false;
    }
    if(!this.user_id){
      console.warn("trying to call claims.isOwner() while claims.user_id is not set (this will always be false...)");
      return false;
    }
    return (appData.owner && appData.owner === this.user_id) || this.isAdmin();
  }
  isContributor(appId){
    return (!!this.contributes[appId]) || this.isAdmin();
  }
}

export default function useClaims(){
  const {claims} = useContext(FirebaseContext);
  return Object.assign(new Claims(), claims);
}