import StackdriverErrorReporter from 'stackdriver-errors-js';

import firebaseConfig from "./firebaseConfig";

const isDisabled = process.env["NODE_ENV"] === "development";
export default function initErrorHandler(){
  if(isDisabled) console.info("Error reporting is disabled");
  const errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: firebaseConfig.apiKey,
    projectId: firebaseConfig.projectId,

    // The following optional arguments can also be provided:

    // service: myServiceName,
    // Name of the service reporting the error, defaults to 'web'.

    // version: myServiceVersion,
    // Version identifier of the service reporting the error.

    // reportUncaughtExceptions: false
    // Set to false to prevent reporting unhandled exceptions, default: `true`.

    // reportUnhandledPromiseRejections: false
    // Set to false to prevent reporting unhandled promise rejections, default: `true`.

    disabled: process.env["NODE_ENV"] === "development" ? true : false,
    // Set to true to not send error reports, this can be used when developing locally, default: `false`.

    // context: {user: 'user1'}
    // You can set the user later using setUser()
  });
  return errorHandler;
}
