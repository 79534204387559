import React, {useState} from "react";
import PropTypes from "prop-types";
import {Selector, FakeEvent} from "@holusion/uikit";
import {useTranslation} from "react-i18next";

import {ListConstructor} from "../ListConstructor";

import Icon, {Forward, Link, Close} from "@holusion/uikit/dist/icons.js";

import LinkEditForm from "./LinkEditForm";
import { defaultStyle } from "./linksTypes";



export function LinkItem({onClick, index, actif, ...linkStyle}){

  return (<li data-testid="list-item" className={`  mt-1  ${actif ? "" : "pr-2"}`} id={`link-${index}`}>
    <div className={`d-flex align-items-center border bg-light ${actif && "border-info"}`}>
      <div className="text-center" style={{width:"30px"}}>{index}</div>
      <div data-testid={`link-${index}`} className={`d-flex justify-content-between align-items-center w-100 px-1 ${actif ? "bg-info text-light py-3" : "bg-light py-2"}`} style={{cursor:"pointer"}} onClick={onClick}>
        <span className={`px-2 ${linkStyle.name ? "font-weight-bold" : !linkStyle.text && "font-italic"}`} id={`list-item-${index}`}>{linkStyle.text ||linkStyle.name || linkStyle.shape} {linkStyle.name && <Icon icon={Link}/>}</span>
        {!actif && <Icon icon={Forward}/>}
      </div>
    </div>
  </li>)
}

export function LinksEditor({layout, value:initialValue, theme, medias, name, options, onChange, setSelectedLink}){
  const [value, setValue] = useState(initialValue ||[]);
  const [itemId, setItemId] = useState(null)
  const [shape, setShape] = useState("rect")
  const {t} = useTranslation(["ui,help"]);
  const images = medias? medias.filter(m => /^image\//.test(m.contentType)) : [];

  const handleChange = (idx, v)=> {
    console.log("handle change :", v);
    let newValue;
    if(v === null) newValue = [...value.slice(0, idx), ...value.slice(idx+1)];
    else{
      newValue = [
        ...value.slice(0, idx),
        v,
        ...value.slice(idx+1),
      ]
    }
    setValue(newValue);
    onChange(new FakeEvent(name, newValue)); //itemRef.set({[links]:links}, {merge: true});
  };

  function highlightLink(link){
    setItemId(link)
    setSelectedLink(link)
  }

  const shapes = [
    { name:t("ui:button"), value:"rect" },
    { name:t("ui:custom-shape"), value:"path" },
    { name:"image", value:"image" }
  ]
  

  return (layout === "Base") ? <>
    <div className="list-group-item d-flex align-items-center">
      <span className="font-weight-bold">Nouveau lien </span>
      <div className="input-group ml-auto" style={{width:"250px"}}>
        <Selector data-testid="links-edit-shape-selector" name="shape-selector" className="form-control border-rounded" emptyValue={null} value={shape} items={shapes} onChange={(e)=> setShape(e.target.value)}/>
        <div  className=" input-group-append">
          <button data-testid="links-edit-add" onClick={()=>{handleChange(value.length, defaultStyle(shape, theme)); highlightLink(value.length)}} aria-label="add" className="btn btn-info">+</button>
        </div>
        
      </div>
    </div>

    <div className="row no-gutters p-1">
      <div className={`${itemId !== null? "col-6" : "col-12"}`}>
        <ListConstructor name={name}
          items={value}
          renderItem={({item, index})=>(<LinkItem
            key={index}
            handleChange={handleChange}
            onClick={()=>(index === itemId) ? highlightLink(null) : highlightLink(index)}
            index={index}
            actif={index === itemId}
            {...item}
          />)}
        />        
      </div>

      {itemId !== null && <div className="col-6 border-info p-1 mt-1" style={{border : "4px solid"}}>
        <div className="text-right"><button className="btn text-info p-0" onClick={()=>highlightLink(null)}><Icon size={24} icon={Close}/></button></div>
        <LinkEditForm
          linksCount={value.length} 
          onRemove={(index)=> {
            if(window.confirm(t("help:really-delete"))){
              highlightLink(null); 
              handleChange(index, null)
            }
          }}
          images={images} 
          index={itemId} 
          linkStyle={value[itemId]} 
          options={options} 
          handleChange={handleChange}
        />
        
      </div>}
    </div>
  </>
  : <div className="p-4">{t("help:cant-add-links")}</div>
}

LinksEditor.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, value: PropTypes.string})),
  theme: PropTypes.object,
  onChange: PropTypes.func,
}