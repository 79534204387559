import {useContext} from "react";
import {FirebaseContext} from "../context";

import { useDownloadURL } from 'react-firebase-hooks/storage';
import { useTranslation } from "react-i18next";

export function useStorageFile(url){
  const {app} = useContext(FirebaseContext);
  const {t} = useTranslation("errors");
  let ref, failed = false;
  try{
    ref = app.storage().refFromURL(url)
  }catch(e){
    ref = false; //Dummy ref.
    failed = true;
  }

  const [value, loading, error] = useDownloadURL(
    ref
  );
  return [
    value, 
    loading, 
    failed? new Error(t("errors:invalid-url", {what: url})) : error,
    ref? ref: null // ref is rarely needed but can be required by some use cases
  ];
}
/**
 * @param {string} url - the image URL in the form gs://holomouseio.appspot.com/[...]
 * @returns {[string, string]} - an http(s) URL to the requested image or a placeholder, a human-readable text status 
 */
export function useStorageImage(url){
  const {t} = useTranslation("ui");
  const [value, loading, error] = useStorageFile(url);
  if( loading ) return ["/loading_image.gif", t("ui:loading")];
  else if( error ) return ["/failed_image.png", error.message];
  else return [value];
}

export default useStorageFile;