import React, {useContext} from "react";

import { Route, Link, Switch, NavLink } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import FirebaseContext from "../context";
import {useClaims} from "../hooks";

import ApplicationNav from '../routes/application/Nav';
import ItemNav from "../routes/page/Nav";
import AdminNav from "../routes/admin/Nav";
import {Portrait} from "../components";

import logo_holusion from "../img/logo_holusion.svg"

function LangPill({i18n, ln}){
  return (<button className={`bg-transparent dropdown-item${i18n.language === ln ? " active text-primary": ""}`} onClick={()=>i18n.changeLanguage(ln)}>
    {ln.toUpperCase()}
    <span className="sr-only">(current)</span>
  </button>)
}

export default function Navbar({user}){
  const {app} = useContext(FirebaseContext);
  const claims = useClaims();
  const { t, i18n } = useTranslation(["ui"]);
  let userSettings = user ? (<React.Fragment>
    <div className="dropdown-divider"></div>
    {claims.isAdmin() && <NavLink data-testid="admin-link" className="dropdown-item" to="/admin/applications">administration</NavLink>}
    <NavLink data-testid="settings-link" className="dropdown-item" to="/user">{t("ui:nav.settings")}</NavLink>
    <div className="dropdown-divider"></div>
    <button data-testid="logout" className="btn dropdown-item text-danger" onClick={()=>app.auth().signOut()}>{t("sign-out")}</button>
  </React.Fragment>) : null;


  return (<nav data-testid="main-navbar" className="navbar navbar-expand-lg navbar-light bg-light">
    <Switch>
      <Route path={["/admin", "/applications/*"]}>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#inner-navigation" aria-controls="inner-navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </Route>
    </Switch>

    <Link className="navbar-brand" title={t("ui:home")} to="/"><img alt="Logo holusion en texte blanc" height={40} src={logo_holusion}/></Link>

    <div className="nav-item dropdown order-lg-3 ml-auto">
      <button data-testid="user-dropdown" className="btn nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {user ?(<Portrait {...user}/>) : t("nav.settings")}
      </button>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
        <small className="text-muted">{t("nav.lang")} :</small>
        <LangPill i18n={i18n} ln="fr"/>
        <LangPill i18n={i18n} ln="en"/>
        {userSettings}
      </div>
    </div>

    {user && <Switch >
      <Route path="/applications/:app/pages/:page">
        <ItemNav/>
      </Route>
      <Route path="/applications/:app">
        <ApplicationNav/>
      </Route>
      <Route path="/admin">
        {claims.isAdmin() && <AdminNav/>}
      </Route>
    </Switch>}
    
  </nav>)
}