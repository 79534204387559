import React from "react";
import PropTypes from "prop-types";

import "./tooltip.css";

import Icon, {Info} from "@holusion/uikit/dist/icons.js";
import {uid as getUid} from "@holusion/uikit";


export default function Tooltip({icon=(<Icon icon={Info}/>), className, children, id=`tooltip-${getUid()}`}){
  return (<span className="tooltip-container">
    <span data-testid="tooltip-icon" aria-describedby={id} className={`tooltip-icon${className?" "+className:""}`}>{icon}</span>
    <div className="tooltip-hover">
      <div className="tooltip-arrow border-primary" />
      <div role="tooltip" id={id} className="tooltip-label bg-primary text-white rounded shadow-sm">
        {children}
      </div>
    </div>
  </span>)
}
Tooltip.propTypes = {
  icon:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  color:PropTypes.string,
}

/* tooltip with a pre-built Info icon */
export function InfoTip({children, ...props}){
  return (<Tooltip icon={<Icon icon={Info}/>} {...props}>
      {children}
  </Tooltip>)
}