import {useState, useEffect} from "react";
import {useToast} from "@holusion/uikit";

export default function usePendingChanges(state) {
  const [addToast, removeToast] = useToast();
  const [taskId, setTaskId] = useState();
  useEffect(()=>{
    if(state){
      setTaskId(addToast({severity:"task", message:"Changes pending...", timeout: 0}));
    }else{
      if(taskId) removeToast(taskId);
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps
  return;
}