export default process.env["REACT_APP_PRODUCTION"]?{
  apiKey: "AIzaSyAnBg4nCi4JopsVS7Eh_2jTtpJKS2tPeMY",
  authDomain: "content.holusion.com",
  databaseURL: "https://holomouseio.firebaseio.com",
  projectId: "holomouseio",
  storageBucket: "holomouseio.appspot.com",
  messagingSenderId: "16774029953",
  appId: "1:16774029953:web:3e64f85be479eab8a0e380"
}:{
  apiKey: "AIzaSyAb9u5ReRnr3lT2oG0sUqFkwg-qx828Tv4",
  authDomain: "holomouseio-staging.firebaseapp.com",
  databaseURL: "https://holomouseio-staging.firebaseio.com",
  projectId: "holomouseio-staging",
  storageBucket: "holomouseio-staging.appspot.com",
  messagingSenderId: "102388524371",
  appId: "1:102388524371:web:6482c0e875f28c7304ada6"
};
