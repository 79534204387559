import  {useContext, useEffect, useState} from "react";
import {relative} from "path";
import {FirebaseContext} from "../context";

import {useCollection} from "react-firebase-hooks/firestore";
import {useClaims} from ".";

export function deduplicate(docs, field="name"){
  const documents = new Map(docs.map(doc=>{
    return [doc.data()[field], doc]
  }));
  return Array.from(documents.values());
}

export default function useMedias(appId, {orderBy="name", withUsage=false}={}){
  const claims = useClaims();
  const isContributor = claims.isContributor(appId);
  const {app} = useContext(FirebaseContext);
  const [mediaDocs, loadingMedias, mediaError] = useCollection(
    appId? app.firestore().collection(`applications/${appId}/medias`).orderBy(orderBy).orderBy("generation"): undefined
  )
  if(!appId){
    return [null, false, new Error("an app id is required. Received "+appId)];
  }
  if(!isContributor){
    return [null, false, new Error(`User is not a contributor on ${appId}`)];
  }
  if(!mediaDocs || ! Array.isArray(mediaDocs.docs)){
    return [mediaDocs, loadingMedias, mediaError];
  }
  //Docs are guaranteed to be properly ordered because generation in monotonically increasing
  const documents = deduplicate(mediaDocs.docs);
  //Filter documents from an outdated generation
  return [
    {size: documents.size, docs: documents},
    loadingMedias,
    mediaError
  ]
}

export function useMedia(appId, name){
  const {app} = useContext(FirebaseContext);
  const [mediaDocs, loadingMedias, mediaError] = useCollection(
    app.firestore().collection(`applications/${appId}/medias`).where("name", "==", name.replace(/\//g, "%2F")).orderBy("generation")
  )
  if(!mediaDocs || ! Array.isArray(mediaDocs.docs)){
    return [null, loadingMedias, mediaError];
  }else if(mediaDocs.size === 0 && !loadingMedias ){
    const e = new Error();
    e.toString = ()=> `Error : no document named ${name} exists`
    return [null, false, e];
  }
  //Docs are guaranteed to be properly ordered because generation in monotonically increasing
  return [
    mediaDocs.docs[mediaDocs.size -1],
    loadingMedias,
    mediaError
  ]
}

/*
 * List all media files in a project directly from storage
 * The list from firestore (default export) is preferred as it is self-updating
 * returns : [{images, videos}, loaded, error]
 */
export function useStorageMedias(project_id){
  const {app} = useContext(FirebaseContext);
  const [medias, setMedias] = useState({images:[], videos:[], loading: true, error: null});

  useEffect(()=>{
    const storageRef = app.storage().ref();
    const localFolder = storageRef.child(`/applications/${project_id}`);
    let is_mounted = true;
    mapStorageFolder(localFolder)
    .then(items=> items.map(item => {
      const localPath = relative(localFolder.location.path, item.path)
      return { name: localPath, localPath: localPath, ...item};
    }))
    .then(items=>{
      const videos = [];
      const images = [];
      for (let item of items){
        if(item.deleted) continue;
        if(/.*\.mp4$/i.test(item.path)){
          videos.push(item);
        }else if(/.*\.(?:png|jpg|svg)$/i.test(item.path)) {
          images.push(item);
        }
      }
      is_mounted && setMedias({images, videos, loading: false});
    }).catch(e=>{
      console.error("Failed to load project medias: ",e);
      is_mounted && setMedias({error: new Error(e.message), loading: false});
    })
    return ()=>{ is_mounted = false};
  },[app, project_id]);
  return [
    { images: medias.images, videos: medias.videos }, 
    medias.loading,
    medias.error,
  ]
}


function mapStorageFolder(ref){
  return ref.listAll().then(res=>{
    const ops = [];
    ops.push(...res.prefixes.map(mapStorageFolder));

    ops.push(Promise.resolve(res.items.map(item => {return {
      deleted: item.authWrapper.deleted || false, 
      uri: `gs://${item.location.bucket}/${item.location.path}`,
      path: item.location.path,
    }})));
    return Promise.all(ops);
  }).then(a=> a.flat());
}