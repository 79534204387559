import React from "react";

import { useParams, useRouteMatch} from "react-router-dom";

import { useTranslation } from 'react-i18next';

import {InnerNav} from "../../components";

export default function ApplicationNav(){
  const { t, } = useTranslation(["ui"]);
  const {app} = useParams();
  const {url} = useRouteMatch();

  return (<InnerNav data-testid="inner-nav-application"
    brand={{to:`${url}/pages`, content: (<span className="d-flex align-items-center">
      <span className="pl-2">{app}</span>
    </span>)}}
    links={[
      {to: `${url}/pages`, content: t("nav.pages")},
      {to: `${url}/settings`, content: t("nav.settings")},
      {to: `${url}/medias`, content: t("nav.medias")},
      {to: `${url}/theme`, content: t("nav.theme")}
    ]}
  />)
}
