import React from "react";
import {useTranslation} from "react-i18next";

export function NoVideoMessage(){
    const {t} = useTranslation("tutorial");
    return (
    <div className="embed-responsive-item mt-5 text-muted">
        <p>{t("no-video")}</p>
        <p>{t("video-edition")}</p>
    </div>)
}