import React, {useState} from "react";
import {ChromePicker} from "react-color";

import {FakeEvent} from "@holusion/uikit";

/**
 * @typedef {Object} RGBAColor
 * @property {number} r - 0-255 Red value
 * @property {number} g - 0-255 Green value
 * @property {number} b - 0-255 Blue value
 * @property {number} a - 0-1   Alpha value
 */

/**
 * 
 * @param {string} c - a color in #RRGGBB[AA] or #RGB form 
 * @returns {RGBAColor}
 */
export function parseColor(c){
  let s = new Option().style;
  s.backgroundColor = c; //use background-color for rgba() support
  if(s.backgroundColor === "") throw new Error(`Invalid color : "${c}"`);
  const [r, g, b, a=1] = s.backgroundColor.split("(")[1].split(")")[0].split(",").map(x=>+x);
  return {r, g, b, a};
}

export function safeParseColor(c){
  try{
    return parseColor(c)
  }catch(e){
    console.warn(e.message);
    return {r:0, g:0, b:0, a:0};
  }
}


/**
 * Parses react-color change object into a 8-char hex color string
 * @param {Object} v - react-color onChangeComplete value
 * @param {Object} v.rgb - rgb[a] numeric values
 * @param {number} [v.rgb.a] - alpha value (0..1)
 * @param {string} v.hex - hex RGB value with a leading #
 * @returns {string} - #RRGGBBAA value
 * @see https://casesandberg.github.io/react-color
 */
export function _parseColorValue({rgb}){
  return "#"+[rgb.r, rgb.g, rgb.b, Math.round(rgb.a*255)].map(v=> v.toString(16).padStart(2, "0")).join("");
}

export function ColorPicker({color, name, onChange, style, ...rest}){
  const [open, setOpen] = useState(false);
  const [RGBA, setRGBA] = useState(safeParseColor(color));

  const handleChange = (typeof onChange === "function" && name) ? (c) =>{
    let v = _parseColorValue(c);
    onChange(new FakeEvent(name, v))
  } : null;

  return (<div {...rest} style={{display: "flex", ...style}}>
    <button data-testid="color-pick-open" onClick={()=>setOpen(true)} disabled={!handleChange } className="btn w-100 p-0 m-0">
      <div data-testid="color-pick-ref" style={{border:"gray solid 1px", minWidth:"1em", height: "1em", background:color}}></div>
    </button>
    {(open && handleChange)? <div className="position-absolute" style={{top: 0, left:"100%", zIndex: 1000}}>
      <div className="position-fixed" onClick={()=>setOpen(false)} style={{top: 0, left: 0, bottom: 0, right: 0}}></div>
      <div style={{zIndex:4}}>
        <ChromePicker data-testid="color-pick-picker" color={RGBA} onChange={(c)=>setRGBA(c.rgb)} onChangeComplete={handleChange}/>
      </div>
    </div> : null }
  </div>)
}