import React from "react";
import PropTypes from "prop-types";
import {Selector} from "@holusion/uikit";

import {BaseDefaultProps, BasePropTypes, SelectorPropTypes} from "./shapes"
import {withState} from "./withState";

export function FormInputContent(props){
  return(<div className="form-group row">
    <label htmlFor={props.name} className="col-sm-4 col-md-3 col-lg-2 col-form-label">{props.title? props.title : props.name} : </label>
    <div className="col-sm-8 col-md-9 col-lg-10">
      <input className="form-control" type={props.type? props.type : "text"} name={props.name} value={props.value||""} onChange={props.onChange}></input>
    </div>
  </div>)
}

export const FormInput = withState(FormInputContent);
FormInput.propTypes = BasePropTypes;
FormInput.defaultProps = BaseDefaultProps;

export function FormTextAreaContent(props){
  return(<div className="form-group">
    <label htmlFor={props.name} >{props.title? props.title : props.name}</label>
    <textarea type="text" className="form-control" name={props.name} rows="4" value={props.value} onChange={props.onChange} placeholder={props.placeholder || "..."}></textarea>
  </div>)
}

export const FormTextArea = withState(FormTextAreaContent);
FormTextArea.propTypes = BasePropTypes;
FormTextArea.defaultProps = BaseDefaultProps;


export function FormTitleContent(props){
  return(<div id={props.name} className="input-group mb-3">
    <input data-test="form-title-content" title={props.title??props.name} placeholder={props.placeholder??props.title??props.name} type={props.type? props.type : "text"} name={props.name} className="col-sm-8 col-md-9 col-lg-10 form-control" aria-label="Sizing example input" aria-describedby={props.name} value={props.value} onChange={props.onChange} />
  </div>)
}

export const TitleFormInput = withState(FormTitleContent);

TitleFormInput.propTypes = Object.assign({
  minWidth: PropTypes.number,
}, BasePropTypes);

TitleFormInput.defaultProps = BaseDefaultProps;



export function FormSelector({minWidth, ...props}){
  return (<div className="form-group form-row">
    <label htmlFor={props.name} className="col-3 col-form-label" style={{minWidth:minWidth? minWidth : 100}}>{props.title? props.title : props.name}</label>
    <div className="col">
      <Selector className="form-control" {...props}/>
    </div>
  </div>)
}

FormSelector.propTypes = SelectorPropTypes;
FormSelector.defaultProps = BaseDefaultProps;

