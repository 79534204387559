import React, {useState, useCallback} from "react";




export function withState(WrappedComponent){
  return function TextStateWrapper({onChange, value:originalValue, ...props}){
    const [value, setValue] = useState(originalValue);
    const onChildrenChange = useCallback((e)=>{
      setValue(e.target.value);
      if(typeof onChange === "function") onChange(e);
    }, [onChange]);
    
    return (<WrappedComponent onChange={onChildrenChange} value={value} {...props}></WrappedComponent>)
  }
}
