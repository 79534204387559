import React from "react";

import { useRouteMatch } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import {InnerNav} from "../../components";

export default function ApplicationNav(){
  const { t } = useTranslation(["ui", "admin"]);
  const {url} = useRouteMatch();
  return (<InnerNav 
    links={[
      {to: `${url}`, content: t("ui:nav.settings")},
      {to: `${url}/applications`, content: t("nav.applications")},
      {to: `${url}/clients`, content: t("nav.clients")},
      {to: `${url}/backups`, content: t("admin:nav.backup")},
    ]}
  />)
}



