import {useState, useEffect} from "react";
import {convert, getDownloadURLs} from "./MarkdownConvert";
import {dirname} from 'path';

export function useTransformMarkdown(md, medias=[]){
    const [content, setContent] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
  
    useEffect(()=>{
      let cancelled = false;
      const transforms = [convert];
      if(medias && 0 < medias.length){
        transforms.push(getDownloadURLs.bind(null, dirname(medias[0].path)));
      }
      transforms.reduce((mdP, fn)=> mdP.then(val=> fn(val)), Promise.resolve(md))
      .then((res)=>{
        if(!cancelled){
          setContent(res);
          setLoading(false);
        }
      })
      .catch((e)=>{
        if(!cancelled){
          setError(e);
        }
      })
      return ()=> cancelled = true;
    }, [md, medias]);
    return [content, loading, error];
  }
