import React from "react";

export default function ErrorMessage(props){
  //console.warn("Made error message for : ", props);
  let message;
  if(props.message) message = props.message;
  else if(typeof props.toString === "function") message = props.toString()// happens when firebase throws an error
  else message = "Unspecified error";
  return (<div className="jumbotron" data-testid="error-message">
    <h1 className="display-4">{props.title? props.title: "Error!"}</h1>
    <pre style={{whiteSpace:"pre-wrap", overflow: "hidden"}} className="lead">{message}</pre>
    {props.stack && <code><pre>{props.stack}</pre></code>}
  </div>)
}