import {useToast, useModal} from "@holusion/uikit";
import {useTranslation} from "react-i18next";
import {TextInputModal} from "@holusion/uikit";

import {valid_doc_id_re as name_pattern} from "../components/Inputs/shapes";
import { useHistory, useLocation } from "react-router-dom";


export default function usePageActions(application){

  const [addToast] = useToast();
  const [addModal] = useModal();
  const {t} = useTranslation(["ui", "help", "errors"]);
  const {pathname} = useLocation();
  const isPageEditLocation = /\w*edit\b/.test(pathname)
  let history = useHistory();

  function duplicate(item){
    addModal({
        type: TextInputModal,
        title: `${t("ui:duplicate")} ${item.id}`,
        value: item.id,
        name: t("ui:name"),
        onConfirm: (value)=>{
            if(!new RegExp(name_pattern).test(value)){
                return addToast({severity:"error", message: t("errors:invalid-name")});
            }
            if(/\s+$/.test(value)){
                value = value.replace(/\s+$/, "")
            }
            const firestore = item.ref.firestore;
            const new_doc = firestore.doc(`applications/${application.id}/pages/${value}`)
            const pagesRef = firestore.collection(`applications/${application.id}/pages`)

            firestore.runTransaction(async (tr)=>{
                const {exists} = await tr.get(new_doc);
                const p = await pagesRef.get();
                if(exists) return addToast({severity:"error", message: t("errors:already-exists", {what: value})});
    
                let pages = []
                p.forEach(doc => doc.data().links && pages.push({id : doc.id, data : doc.data()}))
                
                await tr.set(new_doc, item.data());
            })
        }
    })
  }

  function remove(item){
    if(window.confirm(t("help:really-delete", {what: item.id}))){
      item.ref.delete().then(()=>{
        addToast({severity:"info", message: `${item.id} supprimé`});
      }).catch(e=>{
        addToast({severity:"warn", message:t("errors:delete-fail", {what:item.id})});
      })
      history.replace(`/applications/${application.id}/pages`);
    }
  }

  function rename(item){
    addModal({
        type: TextInputModal,
        title: t("ui:titles.rename", {what: item.id}),
        value: item.id,
        name: t("ui:name"),
        onConfirm: (value)=>{
        if(!new RegExp(name_pattern).test(value)){
            return addToast({severity:"error", message: t("errors:invalid-name")});
        }
        if(/\s+$/.test(value)){
            value = value.replace(/\s+$/, "")
        }
        const firestore = item.ref.firestore;
        const new_doc = firestore.doc(`applications/${application.id}/pages/${value}`)
        const pagesRef = firestore.collection(`applications/${application.id}/pages`)
        
        firestore.runTransaction(async (tr)=>{
            const {exists} = await tr.get(new_doc);
            const p = await pagesRef.get();
            if(exists) return addToast({severity:"error", message: t("errors:already-exists", {what: value})});

            let pages = []
            p.forEach(doc => doc.data().links && pages.push({id : doc.id, data : doc.data()}))
            
            for(let page of pages){
              let newLinks = page.data.links
              let linksIndex = newLinks.flatMap((link, i) => link.name === item.id ? i : [])
              
              for(let i of linksIndex){
                  newLinks[i].name = value;
                  await tr.set(pagesRef.doc(page.id), {"links" : newLinks}, {merge : true});                
              }           
            }

            await tr.set(new_doc, item.data());
            await tr.delete(item.ref);
        }).then(()=>{
            if(isPageEditLocation){
                history.replace(`/applications/${application.id}/pages/${value}/edit`);
            }
          })
        }
    })
  }
  return {rename, remove, duplicate}
}