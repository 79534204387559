import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStorageImage } from "../hooks";

/**
 * @typedef {Object} SvgPath
 * @property {string} fill - fill color
 * @property {string} stroke - stroke color
 * @see https://developer.mozilla.org/fr/docs/Web/SVG/Element/path
 */

/**
 * 
 * @param {SvgPath} p
 * @returns {SvgPath} the original element, or a modified version if it was fully transparent. 
 * @see parseColor
 */
export function isTransparent(p){
  if(p.fill && !(/^#.{6}00$/.test(p.fill) || p.fill === "none")) return false;
  else if(p.stroke && p.strokeWidth > 0) return false;
  return true;
}

function SvgShape({isLinksEditor, isSelected, index, shape, d, image, text, textAlign, verticalAlign, textStyle, x, y, width, height, borderRadius, strokeWidth, ...rest}){
  const textX = {left: parseFloat(x), center: parseFloat(x) + (parseFloat(width)/2), right: parseFloat(x)+parseFloat(width)}[textAlign || "center"];
  const textY = {top: parseFloat(y), center: parseFloat(y) + (parseFloat(height)/2), bottom: parseFloat(y) + parseFloat(height) }[verticalAlign || "center"];
  const [refDisplay, setRefDisplay] = useState("hidden");
  const [source] = useStorageImage(image);

  const indexX = x ? x : d?.slice(1,d.search(","))
  const indexY = y ? y : d?.slice(d.search(",") + 1, d.search(" "))

  let s

  if(shape === "rect"){
    s = <>
      <rect data-testid={isSelected ? `selected-link-${index}` : "rect-shape"} style={{outline: isSelected ? "#00a5e8 3px dashed" : ""}} x={x} y={y} width={width} height={height} rx={borderRadius} ry={borderRadius} strokeWidth={strokeWidth} {...rest}/>
      {text && <text x={textX} y={textY} style={textStyle} dominantBaseline={{top:"hanging", center: "middle", bottom: "alphabetic"}[verticalAlign]??"middle"} textAnchor={{left: "start", center: "middle", right: "end"}[textAlign] ?? "middle"}>{text}</text>}
    </>
  }else if(shape === "image"){
    s = <>
      <image data-testid={isSelected ? `selected-link-${index}` :"image-shape"} xlinkHref={image ? source : "/default_image.png"} style={{outline: isSelected ? "#00a5e8 3px dashed" : ""}} x={x} y={y} height={height} width={width}/>
    </>
  }else{
    s = <g >
      <path data-testid="path-shape" d={d} strokeWidth={strokeWidth} {...rest}/>
      {isSelected && <path data-testid={`selected-link-${index}`} style={{outline:"#00a5e8 3px dashed"}} d={d} strokeWidth={strokeWidth/2} {...rest}/>}
    </g>
  }

  return (<g onMouseOver={()=>setRefDisplay("visible")} onMouseOut={()=>setRefDisplay("hidden")} pointerEvents={"auto"}>
      {s}
      {isLinksEditor && <g visibility={refDisplay}>
        <circle cx={indexX} cy={indexY} r="15" fill="#eeeeee"/>
        <text x={indexX} y={indexY} fontSize="18">{index}</text>         
      </g>}
    </g>)
}

export function SvgPaths({paths, width=1024, height=724, selectedLink, ...rest}){
  const {pathname} = useLocation();
  const isLinksEditor = /\w*links\b/.test(pathname)

  if(!paths) return null;

  const paths_with_borders = paths.map(p=>{
    if(isTransparent(p)){
      return {...p, stroke: "black", strokeWidth: 1, strokeDasharray: "4,4"}
    }else{
      return p;
    }
  })
  return (<svg {...rest} viewBox={`0 0 ${width} ${height}`}>
    {paths_with_borders.map((p, index)=> 
    <Link key={index} to={(p.name && p.name !== "" && (isLinksEditor ? `../${p.name}/links` : `./${p.name}`)) || pathname}>
      <SvgShape 
      isLinksEditor={isLinksEditor}
      shape={p.shape} 
      text={p.text} 
      image={p.image}
      textStyle={{
        fill:p.textColor, 
        fontSize:p.fontSize + "px",
      }}
      verticalAlign={p.verticalAlign}
      textAlign={p.textAlign}
      x={p.x} y={p.y} 
      width={p.width} height={p.height}
      borderRadius={p.borderRadius}
      index={index}
      isSelected={index === selectedLink}
      strokeWidth={p.strokeWidth}
      stroke={p.stroke}
      fill={p.fill}
      d={p.d}
      strokeDasharray={p.strokeDasharray}
      />
    </Link>)}
</svg>)
}