import React from "react";
import {useTranslation} from "react-i18next";

export function WelcomeMessage(){
    const {t} = useTranslation("tutorial");
    return( 
    <div className="text-primary m-2">
        <h2 className="mb-4">{t("app-welcome")}</h2>
        <ul>
            <li className="mb-3">{t("app-add-media")}</li>
            <li>{t("page-creation")}<br/>{t("page-creation2")}</li>
        </ul>
    </div>)
}