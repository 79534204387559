import React from "react"
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {StorageImage} from "./Images";



import "./Card.css"


export default function Card(props){
  const image_props ={
    className: "card-img-top img-fluid",
  }

  let image; // image should always end-up valid, text might be null.
  if(typeof props.image ==="object"){
    image = props.image;
  }else if (props.thumb){
    image = (<StorageImage source={props.thumb} {...image_props}/>)
  }else if(props.image){
    image = (<StorageImage source={props.image} {...image_props}/>)
  }else{
    image = (<img data-source="default-image" alt={props.alt?props.alt:""} {...image_props} src="/default_image.png"/>)
  }
  return (<Link data-test="card" id={props.title} className="card-wrap" to={props.url}>
    <div className="card" style={{width: props.width||"auto"}}>
      {image}
      <div className="card-body bg-primary ">
        <h4 data-test="card-title" className="card-title text-white" title={props.title}>{props.title}</h4>
        {props.text && <p data-test="card-text" className="card-text">{props.text}</p>}
      </div>
    </div>
  </Link>)
}

Card.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  width: PropTypes.any,
  onClose: PropTypes.func,
}


export function HorizontalCard(props){
  const image_props ={
    className: "p-1",
    style:{
      width: "80px",
      objectFit: "cover",
      height: "60px"
    }
  }
  let image; 

  if(typeof props.image ==="object"){
    image = props.image;
  }else if (props.thumb){
    image = (<StorageImage source={props.thumb} {...image_props}/>)
  }else if(props.image){
    image = (<StorageImage source={props.image} {...image_props}/>)
  }else{
    image = (<img data-source="default-image" alt={props.alt?props.alt:""} src="/default_image.png" {...image_props}/>)
  }
  return (<Link data-test="card" className="horizontal-card" title={props.title} id={props.title} to={props.url ? props.url : "#"}>
    <div className="d-flex align-items-center" style={{width: props.width||"auto",height:60}}>
      <div>{image}</div>
      {props.text && <div data-test="card-text" className="m-0 text-center" style={{minWidth: "70px"}}>{props.text}</div>}
      {props.icon && <div className="ml-auto m-0 px-2">{props.icon}</div>}
    </div>
  </Link>)
}

HorizontalCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string.isRequired,
  text: PropTypes.any,
  width: PropTypes.any,
  onClose: PropTypes.func,
}
