

export function defaultStyle(shape, theme={}){
  if(shape === "rect"){
    return {
      shape: "rect",
      x: "50", 
      y: "50",
      width: "200", 
      height: "50", 
      borderRadius: "0",
      fill: theme?.color?.primary ?? "#00517d", 
      stroke: "#000000",
      strokeWidth: "0",
      text: "my button",
      textColor: "#ffffff",
      fontSize: "16",
      textAlign: "center",
      verticalAlign: "center"
    }
  }else if (shape === "image"){
    return {
      shape: "image",
      y:"50",
      x:"50",
      width:"200",
      image:""
    }
  }else if(shape === "path"){
    return {
      shape: "path",
      d: "M50,50 h50 v50 h-50 z",
      fill: "#00000000", 
      stroke: "#000000",
      strokeWidth: "0"
    }
  }else{
    throw new Error("Invalid shape: "+shape);
  }
}