import React from "react";
import {join} from 'path';

import * as Showdown from "showdown";

import "react-mde/lib/styles/css/react-mde-all.css";
import "./markdown.css";


import firebase from "firebase/app";

const extensions = [
  { //add class img-fluid to images
    type: 'output',
    regex: new RegExp(`<img\\s?(?!.*class=)(.*)>`, 'g'),
    replace: `<img class="img-fluid" $1>`
  }
]

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions,
});

export function convert(markdown){
  return Promise.resolve(converter.makeHtml(markdown));
}

//This converter uses showdown extensions as well as asynchronous post-processing steps
export async function getDownloadURLs(mediasPrefix, text=""){
  const storage = firebase.app().storage();

  const substrs = [];
  const matcher = /<img .*src="(?!https?:)((gs:\/)?\/?[^"]+)".*\/>/g
  let result;
  let idx = 0
  while((result = matcher.exec(text)) !== null) {
    const offset = result.index;
    //console.log("results : ", result);
    const match = result[0];
    const name = result[1];
    const isGSURI = result[2];
    //save non-matching string
    substrs.push(Promise.resolve(text.slice(idx, offset)));
    let ref;
    if(isGSURI){
      ref = storage.refFromURL(name)
    }else{
      //prepend string with base app directory
      ref = storage.ref(name.indexOf(mediasPrefix) !== 0 ? join(mediasPrefix, name) : name);
    }
    const op = ref.getDownloadURL()
    .catch(e=>{
      console.warn("Fail to get download URL for markdown-embedded image "+name+" : "+e.message);
      return "/failed_image.png";
    })
    .then(url => match.replace(name, url));
    substrs.push(op);
    idx = matcher.lastIndex;
  }

  substrs.push(text.slice(idx));
  return (await Promise.all(substrs)).join('');
}


export async function provideSuggestions(medias, text , triggeredBy) {
  const currentText = text.split(/\s/)[0];

  const suggestions = medias
  .filter(m => /^image\//.test(m.contentType))
  .filter((m)=>{
    return m.name.indexOf(currentText) === 0;
  })
  .map((m)=>({preview: (<span>{m.name}</span>), value: "/"+m.name}))
  .slice(0,5);
  return suggestions;
}


