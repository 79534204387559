import React from "react";

import Icon, {Back,Forward} from "@holusion/uikit/dist/icons.js";
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '../../components';

import "./IPad.css";

export default function Ipad({header, appTheme, medias, children}){

  const {t} = useTranslation("ui");
  const {width=1024, height=768, title=42} = appTheme?.screen ?? {};
  const is_ipad = (width === 1024 && height === 768);
  return <ThemeProvider appTheme={appTheme} medias={medias}>
    <div className={`screen-container${is_ipad? " ipad-screen": ""}`} style={{maxWidth:width, maxHeight: height}}>
      <div className="screen-outline" style={{paddingTop:`${100 * height / width}%` }}>
        <div className="navbar navbar-light screen-title" style={{height:title, display: (title?"flex": "none")}}> 
          <span style={{color: '#00a5e8'}}><Icon icon={Back}/> {t("ui:back")}</span>
          <div className="mx-auto">
            {header}
          </div>
          <span style={{color: '#00a5e8'}}> <Icon icon={Back}/>... <Icon icon={Forward}/></span>
        </div>
        <div className="screen-content" style={{top:title}}>
            {children}
        </div>
      </div>
    </div>
  </ThemeProvider>
}