import React from "react";
import {Selector} from "@holusion/uikit";

import {ColorPicker} from "./ColorPicker";

export default function StyleInput({label, type, ...props}){
  let input
  if(type === "number"){
    input = <input className="form-control p-1" style={{border:"none", maxWidth:"75px", height:"30px"}} type="number" min="0" {...props}/>
  }else if(type === "color"){
    input = <ColorPicker {...props}/>  
  }else if(type === "selector"){
    input = <Selector className="form-control custom-select" style={{border:"none"}} {...props}/>
  } else{
    input = <input className="form-control" style={{border:"none", height:"30px"}} type={type} {...props}/> 
  }
  return(
    <>
      <div className="d-flex p-0" >
        {label && <span className="justify-content-center text-center font-weight-bold" style={{minWidth:30}}>{label}</span>}
        {input}
      </div>
    </>
  )
}